// Get the header
var header = document.querySelector(".navbar");
var sticky = "140";

// Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
function stickyHeader() {
  if (window.pageYOffset > sticky) {
    header.classList.add("navbar--sticky");
  } else {
    header.classList.remove("navbar--sticky");
  }
}

// When the user scrolls the page, execute stickyheader
window.onscroll = function () {
  stickyHeader();
};

// Check if we scroll up or down
//Initial state
var scrollPos = 0;
// adding scroll event
window.addEventListener("scroll", function () {
  // detects new state and compares it with the new one
  if (document.body.getBoundingClientRect().top + 100 > scrollPos) {
    document.body.classList.remove("scrollDown");
    document.body.classList.add("scrollUp");
  } else {
    document.body.classList.add("scrollDown");
    document.body.classList.remove("scrollUp");
  }
  // saves the new position for iteration.
  scrollPos = document.body.getBoundingClientRect().top;
});