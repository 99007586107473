vtxDomReady(function () {
  var campusListLinks = document.querySelectorAll(".campusSelector");
  var campusButtonList = document.querySelectorAll(".campusButton");
  var closeButton = document.querySelector(".pum-close");

  // Set active campus on campus selection
  if (0 < campusListLinks.length) {
    campusListLinks.forEach(function (campus) {
      campus.addEventListener("click", function () {
        localStorage.setItem("activeCampusId", campus.dataset.campusId);
      });
    });
  }

  // close popup after user choice
  if (0 < campusButtonList.length && closeButton) {
    campusButtonList.forEach(function (button) {
      button.addEventListener("click", function () {
        closeButton.click();
      });
    });
  }
});