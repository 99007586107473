// -------------------- Make font-size adapt to text length
vtxDomReady(function () {
  var pageSingleTitle = document.querySelector(".page-header__title--single");
  if (pageSingleTitle) {
    var innerTextLength = pageSingleTitle.innerHTML.length;
    var maxNbChar = 70;
    if (innerTextLength >= maxNbChar) {
      pageSingleTitle.classList.add("heading-size-3");
    }
  }
});