// campusContent : dropdown campus infos
// campusListBtn : display campusContent on click

var campusDropdown = {
  campusDropdownJsData: campusDropdownJsData,
  // eslint-disable-line no-unused-vars
  isDropdownOpen: false,
  campusContentStatus: "closed",
  activeCampusId: 99,
  //99 is the main-site id, we want main-site to be default campus when no campus selected
  init: function init() {
    this.campusDropdownToggle = document.getElementById("campusDropdownToggle");
    this.campusContent = document.getElementById("campusContent");
    this.initDropdown();
    if (this.campusDropdownJsData.campusList.length > 1) {
      this.bindEvents();
      this.getCache();
    }
  },
  initDropdown: function initDropdown() {
    var _this = this;
    this.selectCampus = new TomSelect("#campusDropdown", {
      create: true,
      optionClass: "campus-dropdown__option",
      itemClass: "campus-dropdown__selected",
      valueField: "id",
      labelField: "nom",
      searchField: "nom",
      closeAfterSelect: false,
      options: this.campusDropdownJsData.campusList,
      items: [this.activeCampusId],
      controlInput: "",
      onChange: function onChange(campusId) {
        if (404 !== parseInt(campusId, 10)) {
          console.log("Campus changed to", campusId);
          _this.setActiveCampus(campusId);

          // WINDOW REPLACE
          window.location.replace(_this.selectCampus.options[_this.activeCampusId].permalink);
        }
      },
      onDropdownOpen: function onDropdownOpen() {
        return _this.isDropdownOpen = true;
      },
      onDropdownClose: function onDropdownClose() {
        return _this.isDropdownOpen = false;
      },
      // fix to layout of dropdown wording - fixrs
      render: {
        item: function item(data, escape) {
          var selectorString = "<div><span class=\"campus-dropdown__toggle__label\"><i class=\"icon icon-map\"></i> ".concat(escape(data.nom), "</div>"); //string to be used in the selector
          if (0 === parseInt(data.id, 10)) {
            selectorString = "<div class=\"campus-dropdown__toggle__label--geolocate\">".concat(_x("Geolocation...", "campus selector", "vtx"), "</div>");
          }
          if (404 === parseInt(data.id, 10)) {
            selectorString = "<div class=\"campus-dropdown__toggle__label--geolocate\">".concat(escape(data.nom), "</div>");
          }
          return selectorString;
        },
        option: function option(data, escape) {
          return "<div>".concat(escape(data.nom), "</div>"); //string to be used in the option
        }
      }
    });

    /* disable if this.campusDropdownJsData.campusList empty */
    if (this.campusDropdownJsData.campusList.length === 0) {
      console.log("Campus selector disabled", this.campusDropdownJsData.campusList);
      this.selectCampus.addOption({
        id: 404,
        nom: _x("Region selector not available", "campus selector", "vtx")
      });
      this.selectCampus.setValue(404, false);
      this.selectCampus.disable();
    }
  },
  setActiveCampus: function setActiveCampus(campusId) {
    var _this2 = this;
    this.activeCampusId = campusId;
    this.setCache();
    this.campusContent.classList.add("campusContentHidden");
    this.campusContentStatus = "hidden";

    // ---------------- This is here that we open the campus select dropdown
    var campusListBtn = this.campusContent.querySelector("#campusListBtn");
    if (campusListBtn) {
      campusListBtn.addEventListener("click", function () {
        _this2.openDropdown();
      });
    }
  },
  hideCampusInfo: function hideCampusInfo() {
    this.campusContent.classList.add("campusContentHidden");
    this.campusContent.parentNode.classList.remove("campusDropdownOpen");
    this.campusContentStatus = "hidden";
  },
  openDropdown: function openDropdown() {
    this.selectCampus.open();
    this.campusContent.parentNode.classList.add("campusDropdownOpen");
    this.isDropdownOpen = true;
  },
  closeDropdown: function closeDropdown() {
    this.selectCampus.close();
    this.campusContent.parentNode.classList.remove("campusDropdownOpen");
    this.isDropdownOpen = false;
  },
  bindEvents: function bindEvents() {
    var _this3 = this;
    this.campusDropdownToggle.addEventListener("click", function (e) {
      e.preventDefault();
      if (_this3.isDropdownOpen) {
        _this3.closeDropdown();
      } else {
        _this3.openDropdown();
      }
    });
  },
  getCache: function getCache() {
    this.activeCampusId = localStorage.getItem("activeCampusId");
    if (this.activeCampusId && this.activeCampusId !== "") {
      this.setActiveCampus(this.activeCampusId);
      this.selectCampus.setValue(this.activeCampusId, true);
      //this.setFillInTheBlanks(campusId); //not available yet because we don't have the region slug to set the fill in the blanks
    }
  },
  setCache: function setCache() {
    if (this.activeCampusId) {
      localStorage.setItem("activeCampusId", this.activeCampusId);
    }
  },
  setFillInTheBlanks: function setFillInTheBlanks(campusId) {
    var activeCampus = this.campusDropdownJsData.campusList.find(function (campus) {
      return campus.id === parseInt(campusId, 10);
    });
    if ("undefined" !== typeof fillInTheBlanks && fillInTheBlanks.moduleReady) {
      fillInTheBlanks.filtersSelect.where.setValue(activeCampus.region.slug); //activeCampus.region.slug is to be defined
    }
  }
};
vtxDomReady(campusDropdown.init());