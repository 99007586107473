/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

var __ = wp.i18n.__;
var _x = wp.i18n._x;
var _n = wp.i18n._n;
var _nx = wp.i18n._nx;
var vtxDomReady = function vtxDomReady(fn) {
  if (typeof fn !== "function") {
    return;
  }
  if (document.readyState === "interactive" || document.readyState === "complete") {
    return fn();
  }
  document.addEventListener("DOMContentLoaded", fn, false);
};
function initCircularText(parentContainerClass, textContainerClass) {
  var bubbles = document.querySelectorAll(parentContainerClass);
  bubbles.forEach(function (element) {
    element.querySelectorAll(textContainerClass).forEach(function (el) {
      var NS = "http://www.w3.org/2000/svg";
      var xlinkNS = "http://www.w3.org/1999/xlink";
      var svg = document.createElementNS(NS, "svg");
      var circle = document.createElementNS(NS, "path");
      var text = document.createElementNS(NS, "text");
      var textPath = document.createElementNS(NS, "textPath");
      svg.style.display = "block";
      svg.style.overflow = "visible";
      text.style.fill = "currentColor";
      circle.style.fill = "none";
      svg.setAttribute("viewBox", "0 0 100 100");
      circle.setAttribute("d", "M0,50 a50,50 0 1,1 0,1z");
      circle.setAttribute("id", "circle");
      textPath.textContent = el.textContent;
      textPath.setAttributeNS(xlinkNS, "xlink:href", "#circle");
      text.appendChild(textPath);
      svg.appendChild(circle);
      svg.appendChild(text);
      el.textContent = "";
      el.appendChild(svg);
    });
  });
}
var isElementXPercentInViewport = function isElementXPercentInViewport(el, percentVisible) {
  var rect = el.getBoundingClientRect(),
    windowHeight = window.innerHeight || document.documentElement.clientHeight;
  return !(Math.floor(100 - (rect.top >= 0 ? 0 : rect.top) / +-rect.height * 100) < percentVisible || Math.floor(100 - (rect.bottom - windowHeight) / rect.height * 100) < percentVisible);
};
(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function init() {
        // detects if user is using the keyboard to navigate and if so, adds a class to body
        function handleFirstTab(e) {
          if (e.keyCode === 9 || e.keyCode === 32 || e.keyCode === 37 || e.keyCode === 39) {
            // the "I am a keyboard user" key
            document.body.classList.add("user-is-tabbing");
            window.removeEventListener("keydown", handleFirstTab);
          }
        }
        window.addEventListener("keydown", handleFirstTab);

        //credits..
        // console.log(
        //   '%c  \n' +
        //   '%cVortex Solution \n' +
        //   'https://www.vortexsolution.com',
        //   'font-size:30px; background: #fff url(https://www.vortexsolution.com/wp-content/themes/vortex-solution/assets/favicon/favicon-32x32.png) no-repeat; color:#fff;',
        //   'background: #fff; color:#00a6e9;font-weight:bold;'
        // );

        document.querySelectorAll('a[href^="#"]').forEach(function (anchor) {
          anchor.addEventListener("click", function (e) {
            e.preventDefault();
            document.querySelector(this.getAttribute("href")).scrollIntoView({
              behavior: "smooth"
            });
          });
        });
        function animateRedSection(section) {
          if (isElementXPercentInViewport(section, 30)) {
            section.classList.add("animateRedSection");
          }
        }
        document.addEventListener("scroll", function () {
          document.querySelectorAll(".red-section").forEach(function (redSection) {
            animateRedSection(redSection);
          });
        });
      },
      finalize: function finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    home: {
      init: function init() {
        // JavaScript to be fired on the home page
      },
      finalize: function finalize() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    about_us: {
      init: function init() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function fire(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";
      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function loadEvents() {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, "_").split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, "finalize");
      });

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.